import { AppBar, Box, Drawer, IconButton, Stack, Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import React from "react";
import { IWorld, UniversalisService } from "../../service/UniversalisService";
import WorldSelector from "../WorldSelector/WorldSelector";
import DrawerContent from "../Drawer/DrawerContent";
import HelpContent from "../Drawer/HelpContent";

interface TopBarProps {
    onWorldChanged: (world?: IWorld) => void;
    world?: IWorld;
}

interface TopBarState {
    drawerOpen: boolean;
    helpOpen: boolean;
}

class TopBar extends React.Component<TopBarProps, TopBarState> {
    constructor(props: TopBarProps) {
        super(props);
        this.state = {
            drawerOpen: false,
            helpOpen: false
        };
    }

    render() {
        return (
            <React.Fragment>
            <AppBar component={'nav'} position="absolute">
                <Toolbar
                    sx= {{
                        justifyContent: 'space-between',
                    }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.onMenuClick.bind(this)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <WorldSelector
                        onWorldSelected={this.props.onWorldChanged}
                    />
                    <IconButton
                        color="inherit"
                        aria-label="open help"
                        edge="end"
                        onClick={this.onHelpClick.bind(this)}
                        sx={{ ml: 2 }}
                    >
                        <HelpIcon />
                    </IconButton>
                </Toolbar>
                <Drawer
                    anchor="left"
                    open={this.state.drawerOpen}
                    onClose={this.onDrawerClose.bind(this)}>
                    <DrawerContent
                        onClose={this.onDrawerClose.bind(this)}
                        onDisconnect={() => {
                            UniversalisService.close();
                            this.props.onWorldChanged(undefined);
                        }}
                    />
                </Drawer>
                <Drawer
                    anchor="right"
                    open={this.state.helpOpen}
                    onClose={this.onHelpClose.bind(this)}>
                    <HelpContent
                        onClose={this.onHelpClose.bind(this)}
                    />
                </Drawer>
            </AppBar>
            <Toolbar/>
            </React.Fragment>
        );
    }
    onMenuClick() {
        const newState = !this.state.drawerOpen;
        this.setState({drawerOpen: newState});
    }
    onDrawerClose(event: React.KeyboardEvent | React.MouseEvent) {
        if (event.type === 'keydown' && 
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        this.setState({drawerOpen: false});
    }
    onHelpClick() {
        const newState = !this.state.helpOpen;
        this.setState({helpOpen: newState});
    }
    onHelpClose(event: React.KeyboardEvent | React.MouseEvent) {
        if (event.type === 'keydown' && 
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        this.setState({helpOpen: false});
    }
}

export default TopBar;