import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

interface HelpContentProps {
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;

}

interface HelpContentState {

}

class HelpContent extends React.Component<HelpContentProps, HelpContentState> {
    render() {
        return (
            <Box
                sx={{ width: '310px', height: '100%' }}
                role="presentation"
                onKeyDown={this.props.onClose}
                bgcolor="primary.main"
            >
                <Paper sx={{ m: 1, p: 1 }} elevation={3}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>What is XIVSales?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                XIVSales is a tool for tracking the latest sales from a world in Final Fantasy XIV.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>How do I use it?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Select your Data Center and world from the top bar. XIVSales will automatically load the latest sales from that world.
                                Please note, that when you change world, the recorded data is reset.</p>
                            <p>The sales will be automatically updated as new sales arrive.</p>
                            <p>Once you have received some sales, you can see them listed in the data table, which typically fills the rest of the screen.
                                There is one row per item. Quantity is the number of items sole. Price is the average price of all the sales. 
                                Total is the sum of all sales for that item.</p>
                            <p>You can sort the sales by clicking on the column headers.</p>
                            <p>You can also filter by different columns by clicking on the three dots in the header, and selecting "filter".</p>
                            <p>From the menu, you can open another panel, which will show you the current connection status and the amount of data stored in the database.</p>
                            <p>From there, you can also disconnect from the server, which will also clear the selected world.</p>
                            <p>At the Storage panel, you can also clear the database, which will remove all sales and items from the database.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography>How does it work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Once you have selected a world, the application will connect to Universalis WebSocket 
                            server and subscribe to the sales from the world you have selected.</p>
                            <p>As the sales start streaming from Universalis, the data is stored in a local database in your browser.</p>
                            <p>The application will gradually load  new items from XIVAPI and update the information. This may take some time as we try to avoid hammering the 
                                XIVAPI servers.</p>
                            <p>This data comes from players who are willing to contribute to the Universalis data collection. 
                                Please consider contributing yourself: <a href="https://universalis.app/contribute">Universalis/Contribute</a>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
                <Paper sx={{ m: 1, p: 1 }} elevation={3}>
                    <h2>Special thanks</h2>
                    <p>Huge thanks to both Universalis and XIVAPI for providing their awesome services free of charge!</p>
                    <p>Please consider donating to them, if you can!</p>
                    <p>Universalis: <a href="https://www.patreon.com/universalis">Universalis Patreon</a></p>
                    <p>XIVAPI: <a href="https://www.patreon.com/bePatron?u=13230932">XIVAPI Patreaon</a></p>
                </Paper>
            </Box>
        );
    }
}

export default HelpContent;