import { openDB, IDBPDatabase, IDBPTransaction, deleteDB } from "idb";
import { Utils } from "../utils/Utils";
import { ISalesEvent, ISalesEventHeader } from "./UniversalisService";
import { XivApiService } from "./XivApiService";

export interface IDbStats {
    added: number;
    lastSales: ISalesEvent[];
    salesCount: number;
    itemCount: number;
}

export class Db {
    static db: IDBPDatabase | null = null;
    static itemCache = new Map<number, ISalesEventHeader>();
    static fetchingItem: boolean = false;
    static stats: IDbStats = {
        added: 0,
        lastSales: [],
        salesCount: 0,
        itemCount: 0
    };
    private constructor() {

    }

    static async openDb() {
        console.log("Opening database");
        Db.db = await openDB('ffxiv_what_sells', 3, {
            upgrade: Db.onUpgradeNeeded
        });
    }

    static onUpgradeNeeded(db: IDBPDatabase, oldVersion: number, newVersion: number, transaction: IDBPTransaction<unknown, string[], "versionchange">) {
        console.log("Upgrading database from version " + oldVersion + " to " + newVersion);
        if (oldVersion < 3) {
            if (db.objectStoreNames.contains('sales')) {
                db.deleteObjectStore('sales');
            }
            if (db.objectStoreNames.contains('items')) {
                db.deleteObjectStore('items');
            }
        }
        Db.createItemTable(db);
        Db.createSalesTable(db);
    }

    static createSalesTable(db: IDBPDatabase) {
        console.log("Creating sales table");
        const objectStore = db.createObjectStore('sales', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('itemId', 'itemId', { unique: false });
        objectStore.createIndex('itemName', 'itemName', { unique: false });
        objectStore.createIndex('quantity', 'quantity', { unique: false });
        objectStore.createIndex('timestamp', 'timestamp', { unique: false });
        objectStore.createIndex('pricePerUnit', 'pricePerUnit', { unique: false });
        objectStore.createIndex('total', 'total', { unique: false });
        objectStore.createIndex('hash', 'hash', { unique: true });
    }

    static createItemTable(db: IDBPDatabase) {
        console.log("Creating item table");
        const objectStore = db.createObjectStore('items', { keyPath: 'itemId', autoIncrement: false });
        objectStore.createIndex('itemName', 'itemName', { unique: false });
    }

    static async addSale(sale: ISalesEvent) {
        if (!Db.db) {
            console.error("Database missing in addSale");
            return;
        }
        if(!sale.total) {
            sale.total = sale.quantity * sale.pricePerUnit;
        }
        sale.hash = await Utils.hash(sale);
        const transaction = Db.db.transaction('sales', 'readwrite');
        const objectStore = transaction.objectStore('sales');
        objectStore.add(sale);
        transaction.commit();
        transaction.onabort = () => {
            console.error("Transaction aborted", transaction.error);
        };
        try {
            await transaction.done;
            console.log("Done adding sale");
        }
        catch (err) {
            console.error("Transaction error: ", err);
        }
    }

    static async addSales(sales: ISalesEvent[]) {
        console.log("Adding sales to database: ", sales);
        for (const sale of sales) {
            await Db.addSale(sale);
        }
        Db.updateStats();
    }

    static async getItem(itemId: number, forceFetch: boolean = false): Promise<ISalesEventHeader> {
        let item = Db.itemCache.get(itemId);
        if (!item) {
            item = await Db.db?.transaction('items', 'readonly').objectStore('items').get(itemId);
        }
        if (!item && (!Db.fetchingItem || forceFetch)) {
            Db.fetchingItem = true;
            XivApiService.getItem(itemId).then(item => {
                if (item) {
                    Db.db?.transaction('items', 'readwrite')
                        .objectStore('items')
                        .add(item).then(() => Db.fetchingItem = false);
                }
            });
        }
        if (item) {
            Db.itemCache.set(itemId, item);
        }
        return item || { itemId: itemId, itemName: "Unknown", itemIcon: "" };
    }



    static async getAllSales(startingTimestamp: number, limit: number): Promise<ISalesEvent[]> {
        const query = IDBKeyRange.lowerBound(startingTimestamp);
        const sales = await Db.db?.getAllFromIndex('sales', 'timestamp', query, limit) as ISalesEvent[];
        for (let i = 0; i < sales.length; i++) {
            const item = await Db.getItem(sales[i].itemId);
            if (item) {
                sales[i].itemName = item.itemName;
                sales[i].itemIcon = item.itemIcon;
            }
        }
        Db.updateStats();
        return sales;
    }

    static async getAllSalesSummed(startingTimestamp: number, limit: number): Promise<ISalesEvent[]> {
        const query = IDBKeyRange.lowerBound(startingTimestamp);
        const sales = await Db.db?.getAllFromIndex('sales', 'timestamp', query, limit) as ISalesEvent[];
        const salesMap = new Map<number, ISalesEvent>();
        sales.forEach(sale => {
            const existingSale = salesMap.get(sale.itemId);
            if (existingSale) {
                existingSale.quantity += sale.quantity;
                existingSale.total += sale.total;
                existingSale.pricePerUnit = Math.floor(existingSale.total / existingSale.quantity);
                existingSale.timestamp = Math.max(existingSale.timestamp, sale.timestamp);
            } else {
                salesMap.set(sale.itemId, sale);
            }
        });
        const sums = Array.from(salesMap.values());
        for (let i = 0; i < sums.length; i++) {
            const item = await Db.getItem(sums[i].itemId);
            if (item) {
                sums[i].itemName = item.itemName;
                sums[i].itemIcon = item.itemIcon;
            }
        }
        Db.updateStats();
        return sums;
    }

    static async updateStats() {
        Db.stats.salesCount = await Db.db?.count('sales') || 0;
        Db.stats.itemCount = await Db.db?.count('items') || 0;
    }

    static async truncateSales() {
        await Db.db?.clear('sales');
    }

    static async reset() {
        await Db.db?.clear('sales');
        await Db.db?.clear('items');
        await Db.close();
        await deleteDB('ffxiv_what_sells');
        Db.db = null;
        Db.stats = {
            added: 0,
            lastSales: [],
            salesCount: 0,
            itemCount: 0
        };
    }

    static close() {
        if (Db.db) {
            Db.db.close();
        }
    }
}