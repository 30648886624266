import { serialize, deserialize } from "bson"
import { Utils } from "../utils/Utils";
import { Db } from "./Database"
import { IItem, XivApiService } from "./XivApiService";

const addr = "wss://universalis.app/api/ws";
const subscribeCommand = {
    event: "subscribe",
    channel: "sales/add{world=NN}"
};

export interface IUniversalisStats {
    status: string;
    packets: number;
}

export interface IRawDataCenter {
    name: string;
    region: string;
    worlds: number[];
}
export interface IDataCenter {
    name: string;
    region: string;
    worlds: IWorld[];
}

export interface IWorld {
    id: number;
    name: string;
    dataCenterName: string;
}


export interface ISalesEventHeader extends IItem {
}

export interface ISalesEventBody {
    buyerName: string;
    hq: boolean;
    onMannequin: boolean;
    pricePerUnit: number;
    quantity: number;
    timestamp: number;
    total: number;
    worldID: number;
    worldName: string;
}

export interface ICryptoHash {
    hash: string;
}

export interface ISalesEvent extends ISalesEventHeader, ISalesEventBody, ICryptoHash {
}

export interface ILastSalesEvent {
    itemID: number,
    lastUploadTime: number,
    worldID: number,
    worldName: string
}

export interface ISaleHistoryResponse {
    itemIDs: number[],
    items: {
        [key: number]: {
            itemID: number,
            worldID: number,
            lastUploadTime: number,
            entries: ISalesEventBody[],
            stackSizeHistogram: {
                [key: number]: number
            },
            stackSizeHistogramNQ: {
                [key: number]: number
            },
            stackSizeHistogramHQ: {
                [key: number]: number
            },
            regularSaleVelocity: number,
            "nqSaleVelocity": number,
            "hqSaleVelocity": number,
            "worldName": string
            
        }
    }
}

export class UniversalisService {
    static ws: WebSocket|undefined;
    static voluntaryClose: boolean = false;
    static world: IWorld;
    static stats: IUniversalisStats = {
        status: "disconnected",
        packets: 0
    };

    private constructor() {
    }

    static open(world: IWorld) {
        console.log("Universalis open");
        if(!UniversalisService.ws) {
            console.log("Creating new WebSocket");
            UniversalisService.ws = new WebSocket(addr);
        }
        UniversalisService.ws.binaryType = "arraybuffer";

        UniversalisService.ws.onopen = function() {
            console.log("Universalis connection opened.")
            UniversalisService.stats.status = "connected";
            UniversalisService.stats.packets = 0;
            Db.stats.added = 0;
            XivApiService.stats.added = 0;
            const cmd =  {
                ...subscribeCommand
            }
            cmd.channel = cmd.channel.replace("NN", world.id.toString());
            console.log("Subscribing to channel for world", world, cmd);
            this.send(serialize(cmd));
        }

        UniversalisService.ws.onclose = function() {
            console.log("Universalis connection closed.");
            UniversalisService.ws = undefined;
            UniversalisService.stats.status = "disconnected";
            if(!UniversalisService.voluntaryClose) {
                console.log("Reconnecting to Universalis in 5 seconds.");
                UniversalisService.stats.status = "reconnecting";
                setTimeout(() => UniversalisService.open(world), 5000);
            }
        }

        UniversalisService.ws.onmessage = (evt) => {
            UniversalisService.stats.packets++;
            const message = deserialize(evt.data);
            const sales: ISalesEvent[] = [];
            Db.getItem(message.item, true).then((item: IItem) => {
                message.sales.forEach((sale: ISalesEventBody)  => {
                    const header: ISalesEventHeader = {
                        itemId: message.item,
                        itemName: item.itemName||"Unknown",
                        itemIcon: item.itemIcon||''
                    }
                    const cryptoHash: ICryptoHash = {
                        hash: "onmessage: Hash Not Calculated Yet"
                    }
                    const salesEvent: ISalesEvent = {...header, ...sale, ...cryptoHash};
                    sales.push(salesEvent);
                });
                Db.addSales(sales);
            });
        }
    }

    static close() {
        UniversalisService.voluntaryClose = true;
        if(UniversalisService.ws) {
            UniversalisService.ws.close();
        }
    }

    static async getDatacenters(): Promise<IDataCenter[]> {
        const worldsUrl = 'https://universalis.app/api/v2/worlds';
        const dataCentersUrl = 'https://universalis.app/api/v2/data-centers';

        const worldsResponse = await fetch(worldsUrl);
        const worlds: IWorld[] = await worldsResponse.json();

        const dataCentersResponse = await fetch(dataCentersUrl);
        const rawDataCenters: IRawDataCenter[] = await dataCentersResponse.json();

        const dataCenters = rawDataCenters.map((dc: IRawDataCenter) => {
            const dataCenter = {
                name: dc.name,
                region: dc.region,
                worlds: dc.worlds.map((worldId: number) => {
                    const world = worlds.find((w: IWorld) => w.id === worldId);
                    if(world) world.dataCenterName = dc.name;
                    return world;
                })
            }
            return dataCenter;
        }) as IDataCenter[];
        console.log('datacenters', dataCenters);
        return dataCenters;
    }

    static async getInitialSales(dataCenterName: string, worldName: string, hours: number): Promise<number> {
        const url = `https://universalis.app/api/v2/extra/stats/most-recently-updated?world=${worldName}&Name=${dataCenterName}&entries=100`;
        const response = await fetch(url);
        const {items} = await response.json();
        const itemIDs = items.map((item: ILastSalesEvent) => {
            return item.itemID;
        });
        console.log('Initials sales', items);
        return await UniversalisService.getSaleHistoryForItems(itemIDs, worldName, hours);
    }

    static async getSaleHistoryForItems(itemsIds: number[], worldName: string, hours: number): Promise<number> {
        let numberOfItems = 0;
        const ids = itemsIds.join(',');
        const sinceMillis = hours * 60 * 60 * 1000;
        const nowMillis = new Date().getTime();
        const startMillis = nowMillis - sinceMillis;
        const sinceSecs = Math.floor(sinceMillis / 1000);
        const startSecs = Math.floor(startMillis / 1000);
        const url = `https://universalis.app/api/v2/history/${worldName}/${ids}?entriesToReturn=1800&statsWithin=${sinceMillis}&entriesWithin=${sinceSecs}`;
        const response = await fetch(url);
        const history: ISaleHistoryResponse = await response.json();
        console.log('History', history);
        for(const [key, item] of Object.entries(history.items)) {
            const id = parseInt(key);
            const latestSales = item.entries.filter((sale: ISalesEventBody) => {
                return sale.timestamp > startSecs;
            });
            if(latestSales.length < 1) {
                console.log('No sales for item', id);
                continue;
            }
            const itemData = await Db.getItem(id, true);
            const hash: ICryptoHash = {
                hash: "getSaleHistoryForItems: Hash Not Calculated Yet"
            }
            const sales = latestSales.map((saleBody: ISalesEventBody) => {
                const sale: ISalesEvent = {
                    itemId: id,
                    itemName: itemData.itemName,
                    itemIcon: itemData.itemIcon,
                    ...saleBody,
                    ...hash
                }
                return sale;
            });
            numberOfItems += sales.length;
            await Db.addSales(sales);
        }
        return numberOfItems;
    }
}
