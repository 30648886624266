import React, { useState } from 'react';
import './App.css';
import { MainChart } from './components/MainChart/MainChart';
import { IWorld } from './service/UniversalisService';
import { BottomNavigation, Container } from '@mui/material';
import TopBar from './components/TopBar/TopBar';
import News from './components/News/News';
import HardestCraftingItemsChart from './components/MainChart/HardestCrafdtingItemsChart';
import AmazonAd from './components/Amazon/AmazonAd';

function App() {
  const [world, setWorld] = useState<IWorld>();
  const [hardestVisible, setHardestVisible] = useState<boolean>(true);

  async function worldChanged(w?: IWorld) {
    setWorld(w);
  }

  return (
    <div className="App">
      <Container>
        <TopBar
          onWorldChanged={worldChanged}
        />
        <News/>
        <MainChart
          world={world}
          onDataAvailable={() => {
            setHardestVisible(false);
          }}
        />
      </Container>

    </div>
  );
}

export default App;
