const addr = "https://xivapi.com/";

export interface IItem {
    itemId: number;
    itemName: string;
    itemIcon: string;
}

export interface IXivStats {
    added: number;
    lastItem: IItem;
}

export class XivApiService {
    static stats: IXivStats = {
        added: 0,
        lastItem: {
            itemId: 0,
            itemName: "",
            itemIcon: ""
        }
    };
    static async getItem(itemId: number): Promise<IItem> {
        const response = await fetch(addr + "item/" + itemId);
        const item = await response.json();
        await new Promise(f => setTimeout(f, 100));
        const result = {
            itemId: item.ID,
            itemName: item.Name_en,
            itemIcon: 'https://xivapi.com' + item.Icon
        };

        XivApiService.stats.added++;
        XivApiService.stats.lastItem = result;
        return result;
    }

    static async getHardestCraftingItems(): Promise<IItem[]> {
        const esQuery = {
            indexes: "item",
            columns: "ID,Name,Icon,LevelItem,LevelEquip",
            body: {
                query: {
                    bool: {
                        must: [
                            { match: { IsUntradable: 0 } },
                            { match: { "Recipes.Level": 90 } }
                        ],
                        filter: [
                            {
                                range: {
                                    "ItemSearchCategory.ID": {
                                        gt: 0
                                    }
                                }
                            },
                            {
                                range: {
                                    LevelItem: {
                                        gte: 600
                                    }
                                }
                            }
                        ]
                    }
                },
                from: 0,
                size: 100,
                sort: [
                    {
                        LevelItem: "desc"
                    }
                ]
            }
        }
        const req: RequestInfo = new Request(addr + "search", {
            method: "POST",
            body: JSON.stringify(esQuery),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const response = await fetch(req);
        const items = await response.json();
        const iItems = items.Results.map((item: any) => {
            return {
                itemId: item.ID,
                itemName: item.Name,
                itemIcon: 'https://xivapi.com' + item.Icon
            }
        });
        console.log('Hardest crafting items', iItems);
        return iItems;
    }
}
