import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import { Db } from "../../service/Database";
import { Connection } from "./Connection";
import { Storage } from "./Storage";

interface DrawerContentProps {
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
    onDisconnect: () => void;
}

interface DrawerContentState {

}

class DrawerContent extends React.Component<DrawerContentProps, DrawerContentState> {
    render() {
        return (
            <Box
                sx={{ width: '310px', height: '100%' }}
                role="presentation"
                onClick={this.props.onClose}
                onKeyDown={this.props.onClose}
                bgcolor="primary.main"
            >
                <Connection
                    onDisconnect={this.props.onDisconnect}
                />
                <Storage
                    dbStats={Db.stats}
                />
                <Paper sx={{ m: 1, p: 1 }} elevation={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <h2>Support</h2>
                        </Grid>
                        <Grid item container xs={12} alignItems="center" justifyContent="center">
                            <Button
                                variant="contained"
                                color="success"
                                onClick={this.openPatreon}
                                sx={{ mt: 2, mb: 2, ml: 2, mr: 2, minWidth: '200px' }}
                            >Patreon</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        );
    }

    openPatreon() {
        window.open("https://www.patreon.com/xivsales", "_blank");
    }
}

export default DrawerContent;