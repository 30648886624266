import { Alert, Box, Card, FormControl, Snackbar, Stack } from "@mui/material";
import React from "react";
import { IDataCenter, IWorld, UniversalisService } from "../../service/UniversalisService";
import DataCenterSelector from "./DataCenterSelector";
import WorldByDataCenterSelector from "./WorldByDatacenterSelector";
import './WorldSelector.css';

interface PersistentWorldSelection {
    dataCenter: IDataCenter;
    world: IWorld;
}

interface WorldSelectorProps {
    onWorldSelected: (world: IWorld) => void;
}

interface WorldSelectorState {
    selectedDatacenter?: IDataCenter;
    selectedWorld?: IWorld;
    datacenters?: IDataCenter[];
    loadingDatacenters: boolean;
    showSnackbar: boolean;
    snackMessage: string;
    snackSeverity: 'success' | 'error' |'warning' | 'info';
}

class WorldSelector extends React.Component<WorldSelectorProps, WorldSelectorState> {
    constructor(props: WorldSelectorProps) {
        super(props);
        this.state = {
            loadingDatacenters: false,
            showSnackbar: false,
            snackMessage: '',
            snackSeverity: 'info'
        };
    }

    closeSnackBar() {
        this.setState({
            showSnackbar: false
        });
    }

    snackBar(message: string, severity: 'success' | 'error' | 'warning' | 'info') {
        this.setState({
            showSnackbar: true,
            snackMessage: message,
            snackSeverity: severity
        });
    }
    async componentDidMount() {
        if (!this.state.datacenters && !this.state.loadingDatacenters) {
            this.setState({ loadingDatacenters: true });
            const datacenters = await UniversalisService.getDatacenters();
            this.setState({ datacenters: datacenters, loadingDatacenters: false });
        }
    }

    async componentDidUpdate(prevProps: Readonly<WorldSelectorProps>, prevState: Readonly<WorldSelectorState>, snapshot?: any) {
        if (!this.state.selectedWorld && !this.state.loadingDatacenters && this.state.datacenters != prevState.datacenters) {
            this.loadSelectedWorld();
        }
    }


    selectRandomWorld() {
        if (this.state.datacenters && this.state.datacenters.length > 0) {
            const datacenter = this.state.datacenters[Math.floor(Math.random() * this.state.datacenters.length)];
            const world = datacenter.worlds[Math.floor(Math.random() * datacenter.worlds.length)];
            // Simulate user actions
            setTimeout(() => {
                this.onDataCenterSelected(datacenter);
                setTimeout(() => {
                    this.onWorldSelected(world);
                }, 100);
            }, 100);

            
            this.forceUpdate();
            this.onWorldSelected(world);
        }

    }

    loadSelectedWorld() {
        const worldSelectionString = localStorage.getItem('worldSelection');
        if (!worldSelectionString) {
            if (!localStorage.getItem('randomWorldSelected')) {
                this.snackBar('Select a world to get started. Random world selected as an example.', 'info');
                this.selectRandomWorld();
                localStorage.setItem('randomWorldSelected', 'true');
            }
            return;
        }
        const worldSelection: PersistentWorldSelection = JSON.parse(worldSelectionString);
        console.log('loadSelectedWorld:', worldSelection);
        this.setState({
            selectedDatacenter: worldSelection.dataCenter,
            selectedWorld: worldSelection.world
        });
        this.props.onWorldSelected(worldSelection.world);
    }

    saveSelectedWorld(worldSelection: PersistentWorldSelection) {
        console.log('saveSelectedWorld:', worldSelection);
        localStorage.setItem('worldSelection', JSON.stringify(worldSelection));
    }

    render() {
        let content = <Card variant="outlined">Loading...</Card>;
        if (this.state.datacenters) {
            content =
                <Card variant="outlined">
                    <DataCenterSelector
                        dataCenters={this.state.datacenters || []}
                        onDataCenterSelected={this.onDataCenterSelected.bind(this)}
                        selectedDataCenter={this.state.selectedDatacenter}
                    />
                    <WorldByDataCenterSelector
                        worlds={this.state.selectedDatacenter?.worlds || []}
                        onWorldSelected={this.onWorldSelected.bind(this)}
                        selectedWorld={this.state.selectedWorld}
                    />
                </Card>
        }
        return (
            <Stack spacing={2} direction="row" alignItems="center">
                {content}
                <Snackbar
                    open={this.state.showSnackbar}
                    autoHideDuration={6000}
                    onClose={this.closeSnackBar.bind(this)}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert 
                        severity={this.state.snackSeverity} 
                        onClose={this.closeSnackBar.bind(this)}
                        sx={{width:'100%'}}>
                            {this.state.snackMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        );
    }

    onDataCenterSelected(dataCenter: IDataCenter) {
        console.log('dataCenter selected:', dataCenter);
        this.setState({ selectedDatacenter: dataCenter });
    }

    onWorldSelected(world: IWorld) {
        console.log('world selected:', world);
        this.setState({ selectedWorld: world });
        if (this.state.selectedDatacenter && world) {
            this.saveSelectedWorld({
                dataCenter: this.state.selectedDatacenter,
                world: world
            });
            this.props.onWorldSelected(world);
        }
    }
}

export default WorldSelector;