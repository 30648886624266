import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { Db, IDbStats } from "../../service/Database";
import { Utils } from "../../utils/Utils";

interface StorageProps {
    dbStats: IDbStats;
}

interface StorageState {
    estimate: any;
}

export class Storage extends React.Component<StorageProps, StorageState> {
    timedCall: NodeJS.Timeout;
    constructor(props: StorageProps) {
        super(props);
        this.state = {
            estimate: null
        }
    }

    render() {
        return (
            <Paper sx={{ m: 1, p: 1 }} elevation={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2>Storage</h2>
                    </Grid>
                    <Grid item xs={6}>
                        Size:
                    </Grid>
                    <Grid item xs={6}>
                        {Utils.bytesToHumanReadable(this.state.estimate?.usage || 0)}
                    </Grid>
                    <Grid item xs={6}>
                        Sales Records:
                    </Grid>
                    <Grid item xs={6}>
                        {this.props.dbStats?.salesCount}
                    </Grid>
                    <Grid item xs={6}>
                        Item Records:
                    </Grid>
                    <Grid item xs={6}>
                        {this.props.dbStats?.itemCount}
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justifyContent="center">
                        <Button 
                            variant="contained"
                            sx={{ width: '150px' }}
                            onClick={() => this.reset()}
                            >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    async reset() {
        await Db.reset();
        localStorage.clear();
        window.location.reload();
    }

    async update() {
        this.setState({
            estimate: await navigator.storage.estimate()
        });
        this.timedCall = setTimeout(() => this.update(), 3000);
    }

    async componentDidMount() {
        await this.update();
    }

    async componentWillUnmount() {
        clearTimeout(this.timedCall);
    }
}
