import CircleIcon from '@mui/icons-material/Circle';
import { Button, Grid, Paper } from '@mui/material';
import React from "react";
import { Db, IDbStats } from "../../service/Database";
import { IUniversalisStats, UniversalisService } from "../../service/UniversalisService";
import { IXivStats, XivApiService } from "../../service/XivApiService";

interface ConnectionProps {
    onDisconnect: () => void;
}

interface ConnectionState {
    dbStats: IDbStats;
    xivStats: IXivStats;
    universalisStats: IUniversalisStats;
}

export class Connection extends React.Component<ConnectionProps, ConnectionState> {
    static runUpdates: boolean = false;

    constructor(props: ConnectionProps) {
        super(props);
        this.state = {
            dbStats: Db.stats,
            xivStats: XivApiService.stats,
            universalisStats: UniversalisService.stats
        }
    }

    async componentDidMount() {
        if (!Connection.runUpdates) {
            Connection.runUpdates = true;
            this.updateStats();
        }
    }

    async componentWillUnmount() {
        Connection.runUpdates = false;
    }

    updateStats() {
        if (Connection.runUpdates) {
            this.setState({
                dbStats: Db.stats,
                xivStats: XivApiService.stats,
                universalisStats: UniversalisService.stats
            });
            setTimeout(() => this.updateStats(), 3000);
        }
    }

    renderConnected() {
        let color: 'success' | 'error' | 'warning' = 'error';
        switch (this.state.universalisStats.status) {
            case 'disconnected':
                color = 'error';
                break;
            case 'connected':
                color = 'success';
                break;
            case 'reconnecting':
                color = 'warning';
                break;
        }
        return (
            <CircleIcon 
                color={color} 
                fontSize="small"
                />
        )
    }
    render() {
        return (
            <Paper sx={{ m: 1, p: 1 }} elevation={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2>Connection</h2>
                    </Grid>
                    <Grid item xs={6}>
                        Status:
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderConnected()}
                    </Grid>
                    <Grid item xs={6}>
                        Packets Received:
                    </Grid>
                    <Grid item xs={6}>
                        {this.state.universalisStats.packets}
                    </Grid>
                    <Grid item xs={6}>
                        New Sales:
                    </Grid>
                    <Grid item xs={6}>
                        {this.state.dbStats.added}
                    </Grid>
                    <Grid item xs={6}>
                        New Items:
                    </Grid>
                    <Grid item xs={6}>
                        {this.state.xivStats.added}
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justifyContent="center">
                        <Button 
                            variant="contained"
                            sx={{ width: '150px' }}
                            onClick={() => this.disconnect()}
                            >
                            Disconnect
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    disconnect() {
        localStorage.removeItem('worldSelection');
        this.props.onDisconnect();
        window.location.reload();
    }
}
