import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { IDataCenter } from "../../service/UniversalisService";

interface DataCenterSelectorProps {
    dataCenters: IDataCenter[];
    onDataCenterSelected: (dataCenter: IDataCenter) => void;
    selectedDataCenter?: IDataCenter;
}
 
interface DataCenterSelectorState {
}
 
class DataCenterSelector extends React.Component<DataCenterSelectorProps, DataCenterSelectorState> {
    render() { 
        return ( 
        <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }} size="small">
            <Select 
            value={this.props.selectedDataCenter?.name || ''} 
            onChange={this.onDataCenterSelected.bind(this)}
            label="Data Center"
            labelId="data-center-label"
            >
                {
                    this.props.dataCenters
                    .map(dataCenter => 
                    <MenuItem 
                        key={dataCenter.name} 
                        value={dataCenter.name}>
                            {dataCenter.name}
                    </MenuItem>)
                }
            </Select>
        </FormControl>
         );
    }

    onDataCenterSelected(event: SelectChangeEvent) {
        const dataCenterName = event.target.value;
        const dataCenter = this.props.dataCenters.find(dataCenter => dataCenter.name === dataCenterName);
        if(dataCenter) {
            this.props.onDataCenterSelected(dataCenter);
        }
    }
}
 
export default DataCenterSelector;