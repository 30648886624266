import React, { ReactNode } from 'react';
import { UniversalisService, ISalesEvent, IWorld } from '../../service/UniversalisService';
import { Db, IDbStats } from '../../service/Database';
import { XivApiService, IXivStats } from '../../service/XivApiService';
import { Container } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export interface IMainChartProps {
    world?: IWorld;
    onDataAvailable: () => void;
}
export interface IMainChartState {
    sales: ISalesEvent[];
}

export class MainChart extends React.Component<IMainChartProps, IMainChartState> {
    universalis: UniversalisService;
    columns: GridColDef[];
    timedCall: NodeJS.Timeout|null;

    constructor(props: any) {
        super(props);
        this.state = {
            sales: [],
        }
        this.columns = [
            { field: 'itemId', renderCell: this.renderItemIcon, headerName: '', width: 50, disableColumnMenu: true, sortable: false },
            { field: 'itemName', headerName: 'Item', flex: 1, minWidth: 200, type: 'string' },
            { field: 'quantity', renderCell: this.renderNumberCell, headerName: 'Quantity', width: 80, type: 'number' },
            { field: 'pricePerUnit', renderCell: this.renderNumberCell, headerName: 'Price', width: 80, type: 'number' },
            { field: 'total', renderCell: this.renderNumberCell, headerName: 'Total', width: 80, type: 'number' },

        ];
    
    }

    async update() {
        if(!Db.db) {
            await Db.openDb();
        }
        if(Db.db && this.props.world) {
            Db.updateStats();
            if(Db.stats.salesCount < 1) {
                console.log('No sales records found, fetching initial sales');
                let hours = 1;
                while(await UniversalisService.getInitialSales(this.props.world.dataCenterName, this.props.world.name, hours) < 5) {
                    hours++;
                    if(hours > 24) {
                        console.error('No sales records found in last 25 hours.');
                        break;
                    }
                }
                console.log(`Initial sales fetched for ${hours} hours`);
            }
        }
        if(this.props.world && !UniversalisService.ws) {
            UniversalisService.open(this.props.world);
        }
        this.refreshData();
        this.timedCall = setTimeout(() => {
            this.update();
        }, 3000);
    }
    async componentDidMount() {
        if(!Db.db) {
            await Db.openDb();
        }
        if(!this.timedCall) {
            await this.update();
        }
    }

    async componentWillUnmount() {
        if(this.timedCall) {
            clearTimeout(this.timedCall);
        }
        this.timedCall = null;
    }

    async componentDidUpdate(prevProps: Readonly<IMainChartProps>, prevState: Readonly<IMainChartState>, snapshot?: any) {
        if(this.props.world && prevProps.world && this.props.world != prevProps.world) {
            if(this.timedCall) {
                clearTimeout(this.timedCall);
            }
            await Db.reset();
            await this.update();
        }
    }

    renderItemIcon(params: GridRenderCellParams<number>) {
        return (
            <a href={"https://garlandtools.org/db/#item/" + params.value} target="_new">
                <img src={params.row.itemIcon} alt={'' + params.value} />
            </a>
        );
    }

    renderNumberCell(params: GridRenderCellParams<number>) {
        return (
            <span>{params.value?.toLocaleString('en-US')}</span>
        );
    }

    async reset() {
        await Db.truncateSales();
    }

    render() {
        return (
        <DataGrid sx={{ mt: 1 }}
            rows={this.state.sales} 
            columns={this.columns}
            getRowId={(row) => row.itemId}  
            autoHeight={true}
            initialState={{
                sorting: {
                    sortModel: [{field: 'total', sort: 'desc'}]
                    }
                }}
            disableColumnSelector={true}
            />
        )
    }
    async refreshData() {
        let startTime = Date.now();
        startTime -= 1000 * 60 * 60 * 24 * 7;
        startTime /= 1000;
        if(Db.db) {
            const dirtyData = await Db.getAllSalesSummed(startTime, 10000);
            const data = dirtyData.filter((row) => {
                return row.itemName != 'Unknown';
            });
            if(data.length > 1) {
                this.props.onDataAvailable();
            }
            this.setState({ sales: data });
        }
    }
}