import { ISalesEventBody } from "../service/UniversalisService";

export class Utils {
    static bytesToHumanReadable(bytes: number, si: boolean = false, dp: number = 1): string {
        const threshold = si ? 1000 : 1024;
        if (Math.abs(bytes) < threshold) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= threshold;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);
        return bytes.toFixed(dp) + ' ' + units[u];
    }
    // Calculate Hash for a Sales Event Body
    static async hash(body: ISalesEventBody): Promise<string> {
        const {buyerName, pricePerUnit, quantity, timestamp} = body;
        const encoder = new TextEncoder();
        const data = `${buyerName}${pricePerUnit}${quantity}${timestamp}`;
        const digest = await crypto.subtle.digest("SHA-256", encoder.encode(data));
        const array = Array.from(new Uint8Array(digest));
        const hex = array.map(b => b.toString(16).padStart(2, "0")).join(""); 
        return hex;
    }

}
