import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { IWorld } from "../../service/UniversalisService";

interface WorldByDataCenterSelectorProps {
    worlds: IWorld[];
    onWorldSelected: (world: IWorld) => void;
    selectedWorld?: IWorld;
}

interface WorldByDataCenterSelectorState {

}

class WorldByDataCenterSelector extends React.Component<WorldByDataCenterSelectorProps, WorldByDataCenterSelectorState> {
    render() {
        return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }} size="small">
                <Select
                    value={this.props.selectedWorld?.name || ''}
                    onChange={this.selectWorld.bind(this)}
                    label="World"
                    labelId="world-select-label"
                    >
                    {
                        this.props.worlds
                            .map(world =>
                                <MenuItem
                                    key={world.name}
                                    value={world.name}>
                                    {world.name}
                                </MenuItem>)
                    }
                </Select>
            </FormControl>
        );
    }
    selectWorld(event: SelectChangeEvent) {
        const worldName = event.target.value;
        const world = this.props.worlds.find(world => world.name === worldName);
        if (world) {
            this.props.onWorldSelected(world);
        }
    }
}

export default WorldByDataCenterSelector;