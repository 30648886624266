import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import NewsItem, { INewsItem } from "./NewsItem";

interface NewsProps {

}

interface NewsState {
    news: INewsItem[];
}

class News extends React.Component<NewsProps, NewsState> {
    render() {
        if(!this.state || !this.state.news) {
            return <Skeleton variant="rectangular" width={'100%'} height={118} />
        }
        return (
            <Stack sx={{mt:2, mb:2}} spacing={2}>

                {this.state.news.map((newsItem, index) => {
                    if(index > 2) {
                        return null;
                    }
                    return (
                        localStorage.getItem(newsItem.title) === null ? 
                            <NewsItem 
                                key={newsItem.title} 
                                newsItem={newsItem} 
                                onNewsItemClose={() => this.newsItemClose(newsItem)}
                            /> : null
                    );
                })}
            </Stack>
        );
    }

    async componentDidMount() {
        if(!this.state || !this.state.news) {
            console.log("Fetching news");
            const url = "/news.json";
            const response = await fetch(url);
            const news = await response.json() as INewsItem[];
            console.log("Fetched news", news);
            this.setState({news: news});
        }
    }

    newsItemClose(newsItem: INewsItem) {
        console.log("News item closed", newsItem);
        localStorage.setItem(newsItem.title, "true");
        const remainingNew = this.state.news.filter((item) => item.title !== newsItem.title);
        this.setState({news: remainingNew});
    }
}



export default News;