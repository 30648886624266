import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export interface INewsItem {
    title: string;
    date: string;
    description: string;
    link?: string;
    linkName?: string;
}

interface NewsItemProps {
    newsItem: INewsItem;
    onNewsItemClose: (newsItem: INewsItem) => void;
}

interface NewsItemState {

}

class NewsItem extends React.Component<NewsItemProps, NewsItemState> {
    render() {
        let linkContent =
            (<Grid item xs={12}>
                <Typography component="p">

                </Typography>
            </Grid>);
        if (this.props.newsItem.link) {
            linkContent = (
                <Grid item xs={12}>
                    <Typography component="p">
                        <a href={this.props.newsItem.link} target="_blank">{this.props.newsItem.linkName}</a>
                    </Typography>
                </Grid>)
        }

        return (
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container spacing={1} alignContent="normal">
                    <Grid item xs={11}>
                        <Typography variant="h6">
                            {this.props.newsItem.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton 
                                color="inherit" 
                                size="small" 
                                onClick={() => this.dismiss()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" fontSize="small">
                            {this.props.newsItem.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="p">
                            {this.props.newsItem.description}
                        </Typography>
                    </Grid>
                    {linkContent}
                </Grid>
            </Paper>
        );
    }
    dismiss() {
        this.props.onNewsItemClose(this.props.newsItem);
    }
}

export default NewsItem;